import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isValidValue } from "./util/generalUtil";

Yup.addMethod(Yup.string, "appendCountryCode", function (countryCode) {
  return this.transform((value) =>
    value?.startsWith("+") ? value : `${countryCode}${value?.slice(1)}`
  );
});

Yup.addMethod(Yup.string, "phonenumber", function (errorMessage) {
  return this.test(`test-phone-type`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      isValidPhoneNumber(value ?? "") ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "emailOrPhone", function (errorMessage) {
  return this.test(
    `test-email-or-phone-type`,
    errorMessage,
    async function (value) {
      const { path, createError } = this;

      let validEmail = true;

      try {
        validEmail = await Yup.string().email().validate(value);
      } catch (e) {
        validEmail = false;
      }

      const phone = Yup.string()
        .appendCountryCode("+234")
        .cast(value ?? "");

      return (
        validEmail ||
        isValidPhoneNumber(phone) ||
        createError({ path, message: errorMessage })
      );
    }
  );
});

Yup.addMethod(Yup.string, "emailAddress", function (errorMessage) {
  return this.test(`test-email-address`, errorMessage, async function (value) {
    const { path, createError } = this;

    let validEmail = true;

    try {
      validEmail =
        value?.length > 0
          ? await Yup.string().email().validate(value)
          : validEmail;
    } catch (e) {
      validEmail = false;
    }

    return validEmail || createError({ path, message: errorMessage });
  });
});

const isValidNumber = (value) => {
  value ??= "";
  const valueTrimmed = value?.trim();
  return (
    valueTrimmed == "" || !isNaN(parseFloat(valueTrimmed?.replace(/\,/g, "")))
  );
};

Yup.addMethod(Yup.string, "numberFormat", function (args, message) {
  const { min, max } = args;

  return this.test(`test-number-format`, message, async function (value) {
    const { path, createError } = this;

    let newVal = parseFloat(value?.replace(/\,/g, ""));

    let isValid = isValidNumber(value);

    if (min) {
      if (newVal < min) {
        message = `${newVal} must be greater than ${min}`;
        isValid = false;
      }
    }

    if (max) {
      if (newVal > max) {
        message = `${newVal} must be less than ${max}`;
        isValid = false;
      }
    }

    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "lessThanOrEqual", function (args, message) {
  return this.test(
    `test-number-lessThanOrEqual`,
    message,
    async function (value) {
      const { path, createError, options } = this;

      const fieldName = args.key;

      if (!fieldName) {
        throw new Error("field name argument is missing.");
      }

      const fieldValue = options.parent[fieldName];
      if (isNaN(fieldValue)) {
        return true;
      }

      let newVal = parseFloat(value?.replace(/\,/g, ""));

      let isValid = isValidNumber(value);

      if (!isValid) {
        createError({ path, message });
      }

      return isValid && newVal <= fieldValue;
    }
  );
});

Yup.addMethod(Yup.string, "lessThan", function (args, message) {
  return this.test(`test-number-lessThan`, message, async function (value) {
    const { path, createError, options } = this;

    const fieldName = args.key;

    if (!fieldName) {
      throw new Error("field name argument is missing.");
    }

    const fieldValue = options.parent[fieldName];

    let newVal = parseFloat(value?.replace(/\,/g, ""));

    let isValid = isValidNumber(value);

    if (!isValid) {
      createError({ path, message });
    }

    return isValid && newVal < fieldValue;
  });
});

Yup.addMethod(Yup.string, "greaterThanOrEqual", function (args, message) {
  return this.test(
    `test-number-greaterThanOrEqual`,
    message,
    async function (value) {
      const { path, createError, options } = this;

      const fieldName = args.key;

      if (!fieldName) {
        throw new Error("field name argument is missing.");
      }

      const fieldValue = options.parent[fieldName];
      if (isNaN(fieldValue)) {
        return true;
      }

      let newVal = parseFloat(value?.replace(/\,/g, ""));

      let isValid = isValidNumber(value);

      if (!isValid) {
        createError({ path, message });
      }

      return isValid && newVal >= fieldValue;
    }
  );
});

Yup.addMethod(Yup.string, "greaterThan", function (args, message) {
  return this.test(`test-number-greaterThan`, message, async function (value) {
    const { path, createError, options } = this;

    const fieldName = args.key;

    if (!fieldName) {
      throw new Error("field name argument is missing.");
    }

    const fieldValue = options.parent[fieldName];
    if (isNaN(fieldValue)) {
      return true;
    }

    let newVal = parseFloat(value?.replace(/\,/g, ""));

    let isValid = isValidNumber(value);

    if (!isValid) {
      createError({ path, message });
    }

    return isValid && newVal > fieldValue;
  });
});

//   console.log("email validated", Yup.string().emailOrPhone().validate("07014385663").then(res => console.log("result", res)))

export const validationTypes = (item) => {
  let { type, mapType } = item;
  let validation = item?.validation || {};
  const MIN_DATE = "1900-1-1";
  switch (type) {
    case "text":
      return Yup.string().nullable();
    case "email":
      return Yup.string().emailAddress("not a valid email").nullable();
    case "date":
      return Yup.date()
        .nullable()
        .min(validation?.min ?? MIN_DATE);
    case "datetime":
      return Yup.date()
        .nullable()
        .min(validation?.min ?? MIN_DATE);
    case "number":
      return Yup.string().numberFormat(validation, "not a valid number");
    case "checkbox":
      return Yup.array();
    case "radio":
      return Yup.string().nullable();
    case "multiselect":
    case "upload":
      return Yup.array();
    case "select":
      return mapType === "mapSelectToString"
        ? Yup.string().nullable()
        : Yup.object().nullable();
    case "phonenumber":
      return Yup.string().phonenumber("not a valid phonenumber").nullable();
    default:
      return Yup.string();
  }
};

function getInputName(name) {
  return name.replace(/_line_\d/, "").replace(/_/g, " ");
}

export const mapValidationSchema = (item, formikStatus = {}) => {
  //if(!item.validation || !item.type) return null;
  let { type, name } = item;
  if (!type) return null;

  let validation = null;

  if (type === "number" && formikStatus) {
    console.log("mapValidationSchema", formikStatus);
  }

  let formikStatusValidation = formikStatus[name]?.validation;
  validation = formikStatusValidation
    ? validationTypes({ ...item, validation: formikStatusValidation })
    : validationTypes(item);

  if (type == "number") {
    const { greaterThan, greaterThanOrEqual, lessThan, lessThanOrEqual } =
      item.validation || {};

    if (isValidValue(lessThanOrEqual)) {
      validation = validation.lessThanOrEqual(
        Yup.ref(lessThanOrEqual),
        `${getInputName(name)} must be less than or equal to ${getInputName(
          lessThanOrEqual
        )}`
      );
    }

    if (isValidValue(lessThan)) {
      validation = validation.lessThan(
        Yup.ref(lessThan),
        `${getInputName(name)} must be less than ${getInputName(lessThan)}`
      );
    }

    if (isValidValue(greaterThanOrEqual)) {
      validation = validation.greaterThanOrEqual(
        Yup.ref(greaterThanOrEqual),
        `${getInputName(name)} must be greater than or equal to ${getInputName(
          greaterThanOrEqual
        )}`
      );
    }

    if (isValidValue(greaterThan)) {
      validation = validation.greaterThan(
        Yup.ref(greaterThan),
        `${getInputName(name)} must be greater than ${getInputName(
          greaterThan
        )}`
      );
    }
  }

  //map required
  if (formikStatusValidation?.required ?? item.validation?.required) {
    validation = validation?.required("This field is required");

    if (["multiselect", "upload"].includes(type)) {
      validation = validation?.min(1, "select at least one item");
    }
  }

  //map default values
  if (item.validation?.default) {
    validation = validation?.default(item.validation?.default);
  }

  return validation;
};
